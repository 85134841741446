header {
  height: 100vh;
  margin-top: 7rem;
  overflow: hidden;
}

.container_header {
  text-align: center;
  height: 100%;
  position: relative;
}

.developer_name_section {
  opacity: 0;
  animation: developer 1s linear forwards;
}

.name_developer {
  animation: develperName 1s linear forwards;
  opacity: 0;
}

@keyframes developer {
  0% {
    transform: translate(200px);
  }
  50% {
    transform: translate(100px);
    opacity: 0.6;
  }
  100% {
    transform: translate(0px);
    opacity: 1;
  }
}

@keyframes develperName {
  0% {
    transform: translate(-200px);
  }
  50% {
    transform: translate(-100px);
    opacity: 0.6;
  }
  100% {
    transform: translate(0px);
    opacity: 1;
  }
}

.container_header:first-child {
  animation-delay: 0.2s;
}

.container_header .text-light {
  animation-delay: 0.5s;
}

/*==============CTA SECTION ==================*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
}

/*==============SOCIAL SECTION ==================*/
.header_social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 1;
  bottom: 10rem;
}

.header_social::after {
  content: "";
  width: 1px;
  height: 3rem;
  background-color: var(--color-primary);
}

/*==============ME SECTION ==================*/
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12em 12em 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/*==============SCROLL DOWN SECTION ==================*/

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 10rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/*===================== MEDIA QUERIES(MEDIUM DEVICES)==========================*/
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/*===================== MEDIA QUERIES(SMALL DEVICES)==========================*/
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header_social,
  .scroll_down {
    display: none;
  }
}
